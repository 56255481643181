const PATHS = {
  //// Auth Routes

  // Shared
  forgotPassword: (appUser: string) => `/${appUser}/auth/forgot-password`,
  resetPassword: (appUser: string) => `/${appUser}/auth/reset-password`,
  signIn: (appUser: string) => `/${appUser}/auth/sign-in`,

  // Customer

  pinCheck: '/customer/auth/pin-check',
  noPinAccess: '/customer/auth/no-pin-access-code',
  verifySMS: '/customer/auth/verify-sms',
  verifyEmail: '/customer/auth/verify-email',
  smsMFA: '/customer/auth/mfa',
  createAccount: '/customer/auth/create-account',
  linkKeiki: '/customer/auth/link-keiki',
  termsConditions: '/customer/auth/terms-conditions',

  // Agency
  onVpn: '/agency/auth/on-vpn',
  totpMfa: '/agency/auth/totp',
  setupMfa: '/agency/auth/totp-setup',

  //// ----------------- //

  //// Shared
  profile: (appUser: string) => `/${appUser}/me/profile`,

  //// Agency Specific
  agencySearch: '/agency/search',
  caseStatus: '/agency/modify/case-status',
  enforcementLicenseStatus: '/agency/modify/enforcement-license-status',
  maxForms: '/agency/modify/max-forms',
  agencySignInUnlock: '/agency/unlock/sign-in',
  agencyLinkUnlock: '/agency/unlock/link-account',
  agencyCases: (pin: string) => `/agency/${pin}/cases`,
  agencySupportBalance: (pin: string) => `/agency/${pin}/support-balance`,
  // Disabling because out of scope for this phase
  // formReport: '/form-report',

  //// Customer Specific
  customerCases: '/customer/me/cases',
  customerSupportBalance: '/customer/me/support-balance',
  // customer only but also public
  faq: '/customer/faq',
};

export default PATHS;
