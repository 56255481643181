// External
import { useContext, useState } from 'react';
import { Button } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import CryptoJS from 'crypto-js';

// Project
import PATHS from 'src/config/paths';
import { TextInput } from 'src/components/Form';
import { useLocalAppUser } from 'src/hooks';

// Features
import { CognitoContext } from '../providers';

const SmsMfa = () => {
  const navigate = useNavigate();
  const { appUser } = useParams();
  const { setLocalAppUser } = useLocalAppUser();
  const { cognitoObj, updateCognitoObj } = useContext(CognitoContext);
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [showResendConfirm, setResendConfirm] = useState(false);

  const handleConfirm = async (event) => {
    event.preventDefault();

    try {
      await Auth.confirmSignIn(cognitoObj, code, 'SMS_MFA');

      sessionStorage.clear();
      updateCognitoObj(undefined);
      const user = appUser === 'customer' ? 'customer' : 'agency';
      setLocalAppUser(user);

      const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const { attributes } = currentUser;

      if (attributes.email_verified) {
        navigate(PATHS.linkKeiki);
      } else {
        navigate(PATHS.verifyEmail);
      }
    } catch (err) {
      console.log('confirmError', err);
      setError('Invalid code. Please try again');
    }
  };

  const handleOnChange = (e) => {
    setCode(e.target.value);
  };

  const handleResendCode = async () => {
    try {
      const username = cognitoObj.username;
      const encryptedPassword = sessionStorage.getItem(`encrypted-code.${username}`);
      const key = sessionStorage.getItem(`encrypt-key.${username}`);
      const password = CryptoJS.AES.decrypt(encryptedPassword, key).toString(CryptoJS.enc.Utf8);
      // attempt to sign in again to trigger sms mfa
      const user = await Auth.signIn(username, password);
      updateCognitoObj(user);
      setResendConfirm(true);
    } catch (error) {
      console.log('resend error', error);
      setError('System error. Try again in a bit.');
    }
  };

  return (
    <>
      <h2 className="text-center">Confirm Code</h2>
      <p className="mb-3">
        An authentication code has been sent to your phone number. Please enter the code below.
      </p>
      <form onSubmit={handleConfirm}>
        <TextInput
          autoComplete="off"
          name="code"
          label="Code"
          value={code}
          placeholder="Authentication Code"
          required
          onChange={handleOnChange}
          error={error !== ''}
        />
        {error && <div className="text-red-600">{error}</div>}
        {showResendConfirm && <p className="text-green-600">Verification code has been resent!</p>}
        <Button variant="contained" className="mt-5 w-full normal-case" type="submit">
          Confirm
        </Button>
      </form>
      <div className="mt-3 flex justify-between">
        <Link to={PATHS.signIn('customer')} className="text-primary no-underline">
          Back to Sign In
        </Link>
        <Button className="p-2 text-base font-normal" onClick={handleResendCode}>
          Resend Code
        </Button>
      </div>
    </>
  );
};

export default SmsMfa;
