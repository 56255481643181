// External
import { useContext, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { API, Auth } from 'aws-amplify';

// Project
import PATHS from 'src/config/paths';
import { TextInput, PasswordInput, Checkbox } from 'src/components/Form';
import { useLocalAppUser } from 'src/hooks';
import { SIGN_IN_TERMS } from 'src/config/messages';

// Feature
import { CognitoContext } from '../../providers';

const DEFAULT_FORM_ERROR_STATE = {
  email: false,
  password: false,
};

export const AgencySignIn = () => {
  const navigate = useNavigate();
  const { appUser } = useParams();
  const { setLocalAppUser } = useLocalAppUser();
  const { updateCognitoObj } = useContext(CognitoContext);

  const [form, setForm] = useState({
    email: '',
    password: '',
    termsAndConditions: false,
  });
  const [formErrors, setFormErrors] = useState(DEFAULT_FORM_ERROR_STATE);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const resetFormErrors = () => {
    setFormErrors(DEFAULT_FORM_ERROR_STATE);
    setError('');
  };

  const updateFormErrorState = (error: string) => {
    setFormErrors((prev) => ({ ...prev, [error]: true }));
  };

  const handleOnChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setForm({ ...form, [name]: newValue });
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    resetFormErrors();

    const { email, password } = form;
    try {
      let user = await Auth.signIn(email, password);
      setLoading(false);

      const userType = appUser === 'customer' ? 'customer' : 'agency';
      setLocalAppUser(userType);
      updateCognitoObj(user);

      switch (user.challengeName) {
        case undefined:
          navigate(PATHS.onVpn);
          break;
        case 'SOFTWARE_TOKEN_MFA':
          navigate(PATHS.totpMfa);
          break;
        case 'MFA_SETUP':
          navigate(PATHS.setupMfa);
          break;
      }
    } catch (err) {
      console.log('SignInError', err);
      switch (err.code) {
        case 'NotAuthorizedException':
          // try {
          //   let response = await API.get('case', `/signin-lockout-status/${email}`, {});
          //   if (response.message.locked) {
          //     setError(LOCKOUT_MESSAGE);
          //   } else {
          //     setError(
          //       'Incorrect email or password. After 3 unsuccessful sign in attempts, you will ' +
          //         'be locked out for a period of 15 minutes. If you are locked out, please contact ' +
          //         'Help Desk.'
          //     );
          //   }
          // } catch (statusErr) {
          //   console.log('GetLockoutStatusError', statusErr);
          //   setError('System error. Please try again later.');
          // }
          setError(
            'Incorrect email or password. After 3 unsuccessful sign in attempts, you will ' +
              'be locked out for a period of 15 minutes.'
          );
          updateFormErrorState('email');
          updateFormErrorState('password');
          break;
        case 'UserNotFoundException':
          setError('Email does not exist. Please try again.');
          updateFormErrorState('email');
          break;
        case 'UserLambdaValidationException':
          const prefix = 'PreAuthentication failed with error ';
          if (err.message.startsWith(prefix)) {
            setError(LOCKOUT_MESSAGE);
          } else {
            setError('System error. Please try again later.');
          }
          break;
        default:
          setError('System error. Please try again later.');
      }
      setLoading(false);
    }
  };

  return (
    <>
      <h1 className="text-center text-2xl">Agency Sign In</h1>
      <form onSubmit={handleSignIn} id="agency-sign-in-form">
        <TextInput
          autoComplete="off"
          name="email"
          label="Email"
          value={form.email}
          placeholder="Email"
          required
          onChange={handleOnChange}
          error={formErrors.email}
          className="mb-5"
        />
        <PasswordInput
          autoComplete="off"
          name="password"
          label="Password"
          value={form.password}
          placeholder="Password"
          required
          onChange={handleOnChange}
          error={formErrors.password}
        />
      </form>
      {error && <div className="my-1 text-red-600">{error}</div>}
      <div className="mt-1">
        <Link to={PATHS.forgotPassword(appUser)} className="text-primary no-underline">
          Forgot Password?
        </Link>
      </div>
      <Checkbox
        checked={form.termsAndConditions}
        onChange={handleOnChange}
        name="termsAndConditions"
        label="I agree with the Terms and Conditions."
      />
      <p className="m-0 text-sm">{SIGN_IN_TERMS}</p>
      <LoadingButton
        variant="contained"
        className="mt-5 w-full normal-case"
        form="agency-sign-in-form"
        type="submit"
        loading={loading}
        disabled={!form.termsAndConditions}
      >
        <span>Sign In</span>
      </LoadingButton>
    </>
  );
};

const LOCKOUT_MESSAGE =
  'You have reached the maximum attempts to Sign In and will be locked out for a period of 15 minutes. For questions or issues regarding how to sign in, please contact Help Desk.';
